<template>
  <v-dialog :max-width="400" :value="!metaFieldValue" persistent>
    <v-card :loading="updating">
      <v-card-title>Выберите ваш регион</v-card-title>
      <v-card-text>
        <v-select
          :items="regions"
          item-value="id"
          item-text="title"
          v-model="value"
          @input="onRegionChange"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!(value || metaFieldValue)"
          :loading="updating"
          text
          @click="onSetRegionClick"
          >Сохранить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  name: "LokotechRegionDialog",
  props: ["item-id", "params"],
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
      getMyProfile: "event/getMyProfile",
    }),
    async setMetaValue(value) {
      this.updating = true;
      const { itemId } = this;
      const command = "submit";
      try {
        await this.runCommand({
          itemId,
          command,
          params: {
            value,
          },
        });
        await this.getMyProfile();
      } catch (err) {
        console.log(err);
      }
      this.updating = false;
    },
    // Lokotech
    onRegionChange(region) {
      this.value = region;
    },
    async onSetRegionClick() {
      await this.setMetaValue(this.value);
    },
  },
  data() {
    return {
      updating: false,
      value: null,
    };
  },
  created() {
    this.value = this.metaFieldValue;
  },
  watch: {
    metaFieldValue(newValue) {
      this.value = newValue;
    },
  },
  computed: {
    ...mapGetters({
      userMeta: "event/userMeta",
    }),
    metaField() {
      return _.get(this.params, "meta-field");
    },
    metaFieldValue() {
      if (!this.metaField) return null;
      return _.get(this.userMeta, this.metaField);
    },
    // Lokotech
    regions() {
      return _.get(this.params, "regions") || [];
    },
  },
};
</script>