<template>
  <app-event event-id="lokotech" :mapping="mapping">
    <template v-slot:logo>
      <v-img :src="require('./assets/logo.v2.png')" class="ma-4" />
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import RegionDialog from "./RegionDialog";
import Quest from "./Quest";

export default {
  name: "AppLokotechPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        meta: RegionDialog,
        quest: Quest,
      },
    };
  },
};
</script>